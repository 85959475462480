import React from 'react'

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"

// Hooks
import useIndex from '../hooks/useIndex'

export default function ThankYouPage() {
    const { mode } = useIndex()

    return (
        <Layout currentPage='Contact' mode={mode}>
            <SEO title="Thank You" />
            <div className={`container__thank-you container__thank-you--${mode}`}>
                <h1 className={`thank-you__title thank-you__title--${mode}`}>Thank you for messaging me. I will get back to you soon.</h1>
            </div>
        </Layout>
    )
}